import React from 'react';
import { PageProps } from 'gatsby';

import Page, { PageBody, PageHeader } from '../components/page';

// TODO: This page is awaiting design/content
const NotFoundPage = ({ location }: PageProps) => (
  <Page>
    <PageHeader location={location} />
    <PageBody>The requested page could not be found</PageBody>
  </Page>
);

export default NotFoundPage;
